import React from 'react'
import styled from 'styled-components'
import ninjaStar from 'images/ninja-star-transparent.png'

import Layout from 'structure/layout'
import SEO from 'structure/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title='Home' />

    <Container>
      <Img src={ninjaStar} alt='queroser.ninja' />
      <P>Não tem nada aqui (404)</P>
    </Container>
  </Layout>
)


const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font: 700 30px sans-serif;
  justify-content: center;
  height: 100%;
`

const Img = styled.img`
  width: 250px;
`

const P = styled.p`
  margin: 0 0 80px;
`

export default NotFoundPage
